import {
  IProject,
  ProjectListState,
  ReducerAction,
  reducerActionType,
} from './models';

export const DEFAULT_LIMIT_SIZE = 100;

export const initialReducerState = () => {
  return {
    projects: [] as IProject[],
    needToResizeLimit: false,
    viewProjectsLimit: DEFAULT_LIMIT_SIZE,
    companies: [] as string[],
    blacklistedSayKitGamesCount: 0,
    blacklistedUnityGamesCount: 0,
  } as ProjectListState;
};

export const reducer = (prevState: ProjectListState, action: ReducerAction) => {
  switch (action.type) {
    case reducerActionType.SET_PROJECTS:
      return { ...prevState, projects: action.payload };

    case reducerActionType.SET_NEED_TO_RESIZE_VIEW_LIMIT:
      return { ...prevState, needToResizeLimit: action.payload };

    case reducerActionType.SET_VIEW_PROJECTS_LIMIT:
      return { ...prevState, viewProjectsLimit: action.payload };

    case reducerActionType.SET_COMPANIES:
      return { ...prevState, companies: action.payload };

    case reducerActionType.SET_SEARCH_FILTER:
      return { ...prevState, searchFilter: action.payload };

    case reducerActionType.SET_SHOW_REQUEST_MODAL:
      return { ...prevState, showRequestModal: action.payload };

    case reducerActionType.SET_SHOW_ADD_NEW_PROJECT_MODAL:
      return { ...prevState, showAddNewProjectModal: action.payload };

    case reducerActionType.SET_BLACKLISTED_SAYKIT_GAMES_COUNT:
      return { ...prevState, blacklistedSayKitGamesCount: action.payload };

    case reducerActionType.SET_BLACKLISTED_UNITY_GAMES_COUNT:
      return { ...prevState, blacklistedUnityGamesCount: action.payload };
    default:
      return prevState;
  }
};

export const filterProjects = (
  filter: string,
  projects: IProject[]
): IProject[] => {
  if (!filter) {
    return projects;
  }
  const parts = filter.trim().toLowerCase().split(/\s+/);

  return projects.filter((p) => {
    for (let i = 0; i < parts.length; i++) {
      if (p._filter.indexOf(parts[i]) === -1) {
        return false;
      }
    }
    return true;
  });
};

export const makeProjectsFilterField = (projects: IProject[]): IProject[] => {
  return projects.map((project) => {
    project._filter =
      project.id +
      ' ' +
      project.appKey.toLowerCase() +
      ' ' +
      project.name.toLowerCase() +
      ' ' +
      project.bundle.toLowerCase() +
      ' ' +
      project.company.toLowerCase() +
      ' ' +
      project.storeTitle.toLowerCase();
    return project;
  });
};

export const areFirstTwoWordsDiff = (title1: string, title2: string) => {
  const getFirstTwoWords = (title: string) => {
    return title.trim().split(/\s+/).slice(0, 2).join(' ').toLowerCase();
  };

  return getFirstTwoWords(title1) !== getFirstTwoWords(title2);
};
