import React from 'react';
import styled from 'styled-components';

const StyledLine = styled.div`
  .linear-activity {
    overflow: hidden;
    width: 100%;
    height: 3px;
    background-color: #fff;
  }

  .indeterminate {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .indeterminate:before {
    content: '';
    position: absolute;
    height: 100%;
    background-color: #00c565;
    animation: indeterminate_first 1.5s infinite ease-out;
  }

  .indeterminate:after {
    content: '';
    position: absolute;
    height: 100%;
    background-color: #00c565;
    animation: indeterminate_second 1.5s infinite ease-in;
  }

  @keyframes indeterminate_first {
    0% {
      left: -100%;
      width: 100%;
    }
    100% {
      left: 100%;
      width: 10%;
    }
  }

  @keyframes indeterminate_second {
    0% {
      left: -150%;
      width: 100%;
    }
    100% {
      left: 100%;
      width: 10%;
    }
  }
`;

export const LineLoader: React.FC = () => {
  return (
    <StyledLine>
      <div className="linear-activity position-absolute">
        <div className="indeterminate" />
      </div>
    </StyledLine>
  );
};
