import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { ROLE_GUEST } from '../services/_utils';

export const WithAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuth, getRole } = useAuth();

  useEffect(() => {
    if (getRole() === ROLE_GUEST && !location.pathname.startsWith('/help')) {
      navigate('/help');
    }
  }, []);

  if (!isAuth()) {
    if (location.pathname !== '/') {
      sessionStorage.setItem('from', location.pathname);
    }
    return <Navigate to="/login" />;
  }

  return children;
};
