import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import { BrowserRouter } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
import { amplitudeApiKey, apiHost } from './environments/environment';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

{
  !apiHost?.startsWith('http://localhost') &&
    amplitude.init(amplitudeApiKey!, localStorage.getItem('login')!, {
      defaultTracking: {
        attribution: false,
        pageViews: true,
        sessions: true,
        formInteractions: false,
        fileDownloads: false,
      },
    });
}

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);
