import { ILLNode } from './models';

export const onSortByIndexArray = (ll: ILLNode[]) => {
  ll.forEach((node) => {
    if (node.Next !== null) {
      node.ShowNext = true;
      node.Next = node.Next.sort((a: ILLNode, b: ILLNode) => {
        return a.IndexOfArray > b.IndexOfArray ? 1 : -1;
      });
      node.Next.forEach((nextNode) => {
        if (nextNode.Next !== null) {
          nextNode.ShowNext = true;
          onSortByIndexArray(nextNode.Next);
        }
      });
    }
  });
  return ll;
};

export const countConfigDiffs = (head: string, diff: any) => {
  let cnt = 0;
  Object.entries(diff).map((a) => {
    if (a[0].startsWith(head)) {
      cnt++;
    }
  });
  return cnt;
};
