import React, { ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { DropdownButton } from 'react-bootstrap';

export interface IDropdownComponentProps {
  children: ReactNode;
  autoClose: boolean | 'outside' | 'inside';
  title: any;
  datatestid?: string;
  classnames?: string;
  onSelect?: (e: SyntheticEvent<HTMLDivElement>) => void;
}

export const DropdownComponent: React.FC<IDropdownComponentProps> = ({
  children,
  datatestid,
  classnames,
  ...restProps
}) => {
  return (
    <Styled
      as={DropdownButton}
      variant="outline-secondary"
      className={classnames}
      data-testid={datatestid}
      {...restProps}
    >
      {children}
    </Styled>
  );
};

const Styled = styled.div`
  .btn {
    height: 36px;
    width: 100%;
    background-color: transparent;
    color: #6c757d;

    //&:hover {
    //  box-shadow: none;
    //  outline: none;
    //}

    //&:focus {
    //  box-shadow: none;
    //  outline: none;
    //}
  }

  .dropdown-menu {
    max-height: 300px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .dropdown-toggle::after {
    margin-left: auto;
    align-self: center;
  }

  .dropdown-toggle {
    white-space: nowrap;
    display: flex;
    border-color: #ced4da;
    text-overflow: ellipsis;
    overflow-x: hidden;
    color: #6c757d;
    background-color: #fff;
    &:hover {
      background-color: #fff;
      border-color: #ced4da;
    }

    &:active {
      background-color: #fff;
      border-color: #ced4da;
    }

    &:focus {
      background-color: #fff;
      border-color: #ced4da;
    }

    &.show {
      color: #6c757d;
      background-color: #fff;
      border-color: #ced4da;
      box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
    }
  }

  .dropdown-item {
    color: #6c757d;
    background-color: transparent;

    &:hover {
      background-color: #ced4da;
    }
  }

  .inputSearch {
    height: 30px;
    font-size: 10px;
  }

  .inputNewCompany {
    .inputRaw {
      height: 30px;
      font-size: 10px;
    }

    .form-check-input {
      width: 14px;
    }
  }
`;
