import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiHost } from '../../environments/environment';
import { toast } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';

export const useFetch = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const request = useCallback(
    async (
      url: string,
      method = 'GET',
      body: string | FormData | null = null,
      blob: boolean = false,
      headers: any = {
        Login: localStorage.getItem('login') as string,
        Role: localStorage.getItem('role') as string,
        Hash: localStorage.getItem('hash') as string,
        Accept: 'application/json',
      }
    ) => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(apiHost + url, {
          method,
          body,
          headers,
        });
        if (response.status === 403) {
          const message = await response.json();
          toast.warn(message.error, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          } as ToastOptions);
          setTimeout(() => {
            fetch(apiHost + 'logout', {
              method: 'POST',
              body: JSON.stringify({ logout: true }),
              credentials: 'include',
            }).then(() => {
              localStorage.clear();
              sessionStorage.setItem('from', '');
              navigate('/login', { replace: true });
            });
          }, 1000);
          if (!!message.error) {
            throw new Error(message.error);
          } else {
            throw new Error(message);
          }
        } else if (response.status === 404) {
          throw new Error('Oooops..');
        } else if (!response.ok) {
          const errMsg = await response.json();
          if (!!errMsg.error) {
            throw new Error(errMsg.error);
          } else {
            throw new Error(errMsg);
          }
        } else {
          if (blob) {
            setLoading(false);
            return await response;
          }
          const data = await response.json();
          setLoading(false);
          return data;
        }
      } catch (e) {
        setLoading(false);
        if (e instanceof Error) {
          setError(e.message);
        }
        throw e;
      }
    },

    []
  );

  return { loading, error, request };
};
