import React from 'react';
import { useParams } from 'react-router-dom';

const SayDiscoveryResult: React.FC = () => {
  const { testExternalID } = useParams();

  return (
    <>
      <iframe
        src={`https://discovery.saygames.io/public-api/saylauncher/test/${testExternalID}`}
        className="w-100"
        style={Object.assign({
          minHeight: 'calc(100vh - 70px)',
        })}
      />
    </>
  );
};
export default SayDiscoveryResult;
