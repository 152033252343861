import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Login } from './features/Login';
import { WithAuth } from './hoc/RequireAuth';
import Header from './features/Header';
import { ProjectList } from './features/ProjectList';
import { VersionsCompare } from './features/Project/VersionsCompare';
import { MCHammer } from './components/Loader';
import { createGlobalStyle } from 'styled-components';
import { MainColor } from './services/_utils';
import SayDiscoveryResult from './features/SayDiscovery/Result';
import SayDiscoveryDashboard from './features/SayDiscovery/Dashboard';

const ProjectHeader = lazy(() => import('./features/Project/ProjectHeader'));
const Localizations = lazy(() => import('./features/Project/Localizations'));
const Experiments = lazy(
  () => import('./features/Project/Experiments/List/index')
);
const ExperimentItem = lazy(
  () => import('./features/Project/Experiments/Item')
);
const Versions = lazy(() => import('./features/Project/Versions/Versions'));
const VersionHeader = lazy(
  () => import('./features/Project/Version/VersionHeader')
);
const VersionView = lazy(() => import('./features/Project/Version'));
const VersionProperty = lazy(
  () => import('./features/Project/Version/VersionProperty')
);
const SayKitSettings = lazy(() => import('./features/Project/SayKitSettings'));
const Builds = lazy(() => import('./features/Project/Builds'));
const Settings = lazy(() => import('./features/Project/Settings'));
const Monetization = lazy(() => import('./features/Project/Monetization'));
const CPIs = lazy(() => import('./features/Project/CPIs'));
const Support = lazy(() => import('./features/Project/Support'));
const Health = lazy(() => import('./features/Health'));
const Help = lazy(() => import('./features/Help/List'));
const HelpFileContent = lazy(() => import('./features/Help/FileContent'));
const Creatives = lazy(() => import('./features/Creatives'));
const Totals = lazy(() => import('./features/Totals'));
const UserIssues = lazy(() => import('./features/Project/UserIssues'));
const LogValidation = lazy(() => import('./features/Project/LogValidation'));
const GamesInfo = lazy(() => import('./features/GamesInfo'));
const SayKitBlackList = lazy(() => import('./features/SayKitBlacklist'));
const Notifications = lazy(() => import('./features/Project/Notifications'));
const Users = lazy(() => import('./features/Users'));
const Inapps = lazy(() => import('./features/Project/Inapps'));
const Devices = lazy(() => import('./features/Devices/List'));
const Device = lazy(() => import('./features/Devices/Device'));
const DeviceLogs = lazy(() => import('./features/Project/DeviceLogs/index'));
const RandomLogs = lazy(
  () => import('./features/Project/DeviceLogs/RandomLogs')
);
const Unity = lazy(() => import('./features/Unity'));
const CrashAnr = lazy(() => import('./features/CrashAnr'));
const ProjectsUpdateHistory = lazy(
  () => import('./features/ProjectReleaseHistory')
);
const ExperimentsGlobalList = lazy(
  () => import('./features/ExperimentsGlobalList')
);

const ContinuousIntegrationDeliveryIntegration = lazy(
  () => import('./features/Project/CiDi')
);

function App() {
  return (
    <Suspense fallback={<MCHammer />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <WithAuth>
              <Header />
            </WithAuth>
          }
        >
          <Route index element={<Navigate to="/projects" />} />
          <Route path="projects" element={<ProjectList />} />
          <Route path="projects/:projectId" element={<ProjectHeader />}>
            <Route index element={<Versions />} />
            <Route path="create_new_build" element={<Versions />} />
            <Route
              path="compare-versions/:list"
              element={<VersionsCompare />}
            />
            <Route path="versions/:versionId" element={<VersionHeader />}>
              <Route index element={<VersionView />} />
              <Route path=":property" element={<VersionProperty />} />
              <Route path=":property/:itemId" element={<VersionProperty />} />
            </Route>
            <Route path="game_messages" element={<Localizations />} />
            <Route path="experiments" element={<Experiments />} />
            <Route
              path="experiments/:experimentId"
              element={<ExperimentItem />}
            />
            <Route path="devices" element={<Devices />} />
            <Route path="device_logs/:deviceId/*" element={<DeviceLogs />} />
            <Route path="device_logs/random" element={<RandomLogs />} />
            <Route path="saykit-settings" element={<SayKitSettings />} />
            <Route path="builds" element={<Builds />} />
            <Route path="settings" element={<Settings />} />
            <Route path="monetization" element={<Monetization />} />
            <Route path="cpi" element={<CPIs />} />
            <Route path="support" element={<Support />} />
            <Route path="notifications" element={<Notifications />}>
              <Route path=":id" element={<Notifications />} />
            </Route>
            <Route path="user-issues" element={<UserIssues />} />
            <Route path="user-issues/*" element={<UserIssues />} />
            <Route path="log-validation" element={<LogValidation />} />
            <Route path="inapps" element={<Inapps />} />
            <Route
              path="cidi"
              element={<ContinuousIntegrationDeliveryIntegration />}
            />
          </Route>

          <Route path="users" element={<Users />} />
          <Route path="devices" element={<Devices />} />
          <Route path="devices/:id" element={<Device />} />
          <Route path="games-info" element={<GamesInfo />} />
          <Route path="games-info/*" element={<GamesInfo />} />
          <Route path="totals" element={<Totals />} />
          <Route path="creatives" element={<Creatives />} />
          <Route path="discovery" element={<SayDiscoveryDashboard />} />
          <Route
            path="discovery_result/:testExternalID"
            element={<SayDiscoveryResult />}
          />
          <Route path="help" element={<Help />} />
          <Route path="help/:helpFileId" element={<HelpFileContent />} />
          <Route path="health" element={<Health />} />
          <Route path="saykit" element={<SayKitBlackList />} />
          <Route path="crash-anr" element={<CrashAnr />} />
          <Route path="unity" element={<Unity />} />
          <Route
            path="games-release-history"
            element={<ProjectsUpdateHistory />}
          />
          <Route path="experiments" element={<ExperimentsGlobalList />} />
          {/*<Route path="tsarevich" element={<Tsarevich />} />*/}
          <Route path="*" element={<Navigate to="/projects" />} />
        </Route>
      </Routes>
      <StyledApp />
    </Suspense>
  );
}

export default App;

const fontSizeBase = 0.9;

const StyledApp = createGlobalStyle`
    html,
    body {
        margin: 0 !important;
        height: 100% !important;
        font-family: -apple-system, 'system-ui', 'Open Sans', sans-serif;
    }


    label {
        margin-bottom: 0.4rem !important;
        font-size: 0.8rem !important;
    }

    h1 {
        font-size: ${fontSizeBase * 2}rem !important;
    }

    h2 {
        font-size: ${fontSizeBase * 1.75}rem !important;
    }

    h3 {
        font-size: ${fontSizeBase * 1.5}rem !important;
    }

    h4 {
        font-size: ${fontSizeBase * 1.25}rem !important;
    }

    h5 {
        font-size: ${fontSizeBase * 1.1}rem !important;
    }

    h6 {
        font-size: ${fontSizeBase}rem !important;
    }

    .btn {
        font-size: ${fontSizeBase}rem !important;
        border-radius: 8px;
    }

    .text-muted {
        color: #adb5bd !important;
    }

    .form-control {
        border-radius: 8px;
        font-size: ${fontSizeBase}rem !important;
    }

    .btn-success {
        border-color: ${MainColor} !important;
        background-color: ${MainColor} !important;
        color: #fff;

        &:disabled {
            border-color: ${MainColor} !important;
            background-color: ${MainColor} !important;
            color: #fff;
        }

        &:active {
            background-color: ${MainColor} !important;
            color: #fff;
        }

        &:focus {
            background-color: ${MainColor} !important;
            color: #fff;
        }

        &:hover {
            color: #fff;
        }
    }

    .bg-success {
        background-color: ${MainColor} !important;
    }

    .btn-outline-success {
        border-color: ${MainColor} !important;
        color: ${MainColor} !important;

        &:hover {
            border-color: ${MainColor} !important;
            background-color: ${MainColor} !important;
            color: #fff !important;
        }
    }

    .btnLink {
        text-decoration: none;

        &:hover {
            box-shadow: none;
            outline: none;
            text-decoration: underline;
        }

        &:focus {
            box-shadow: none;
            outline: none;
            text-decoration: underline;
        }
    }

    table {
        thead,
        tbody,
        tfoot,
        th,
        td {
            border-color: #dee2e6 !important;
        }
    }
  .tooltip-inner {
    padding: 0.5rem 0.75rem;
    background-color: #2E3440;
  }
`;
