import React from 'react';

interface IAppleIcon {
  className: string;
  size?: number;
}

export const AppleIcon: React.FC<IAppleIcon> = ({ className, size = 12 }) => {
  return (
    <img
      src="/img/apple.svg"
      alt="apple_icon"
      className={className}
      width={size}
      height={size}
    />
  );
};
