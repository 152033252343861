import React, { useCallback, useRef } from 'react';
import { ISearchInputProps } from './models';
import { FormControl } from 'react-bootstrap';
import lodash from 'lodash';
import styled from 'styled-components';
import { Search } from 'react-bootstrap-icons';

export const SearchInputComponent: React.FC<ISearchInputProps> = ({
  setValue,
  classNames,
  datatestid,
  placeholder,
  autoFocus,
  disabled,
  child,
  searchRef,
}) => {
  const defaultRef = useRef<HTMLInputElement | null>(null);

  const onSetSearch = useCallback(
    lodash.debounce(function (e: React.ChangeEvent<HTMLInputElement>) {
      setValue(e.target.value);
    }, 300),
    []
  );

  return (
    <StyledSearch className={classNames}>
      <Search className="searchIcon" size={16} color={'#adb5bd'} />
      <FormControl
        ref={!!searchRef ? searchRef : defaultRef}
        data-testid={datatestid}
        placeholder={!!placeholder ? placeholder : 'Search...'}
        onChange={onSetSearch}
        autoFocus={autoFocus}
        spellCheck={false}
        disabled={!!disabled}
      />
      {!!child && child}
    </StyledSearch>
  );
};

const StyledSearch = styled.div`
  position: relative;

  .form-control {
    padding: 0.375rem 0.375rem 0.375rem 2.5rem;
    min-height: 35px;
    height: 100%;
    width: 100%;

    &::placeholder {
      color: #adb5bd;
    }
  }

  .searchIcon {
    background-color: transparent;
    position: absolute;
    left: 15px;
    top: 10px;
    z-index: 80;
  }
`;
