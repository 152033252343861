import React, { useEffect, useState } from 'react';
import { Button, Collapse, Form } from 'react-bootstrap';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { Toaster } from '../../components/Toast/Toast';
import { apiHost } from '../../../environments/environment';
import styled, { keyframes } from 'styled-components';
import { ROLE_GUEST } from '../../services/_utils';

const StyledPage = styled.div`
  background: #00c565;
  min-height: 100vh;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -ms-flex-align: center;
  justify-content: center;
`;

const StyledContent = styled.div`
  width: 300px;
  max-width: 300px;
  text-align: center;
  padding-bottom: 1rem;

  .btn:focus {
    outline: none;
    outline-offset: unset;
    box-shadow: none;
  }
`;

const LauncherImg = styled.img`
  display: block;
  width: inherit;
`;

const _bounce = keyframes`
    30% {
        transform: scale(1) translate(0, 0);
    }
    100% {
        transform: scale(1.05) translate(0, -5px);
    }
`;

const GoogleButtonMain = styled.button`
  display: block;
  border: none;
  background-color: rgba(0, 0, 0, 0.15);
  animation-name: ${_bounce};
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }

  img {
    vertical-align: bottom;
    margin-left: 0.25rem;
    height: 30px;
  }
`;
const GoogleButtonSecondary = styled.button`
  display: block;
  border: none;
  background-color: rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const Login = () => {
  const { isAuth } = useAuth();
  const navigate = useNavigate();
  const { withGoogle, logIn } = useAuth();

  const [openPlanB, setOpenPlanB] = useState(false);
  const [clicks, setClicks] = useState(0);

  let fromPage = '/';
  if (sessionStorage.getItem('from')) {
    fromPage = sessionStorage.getItem('from')!;
  }

  useEffect(() => {
    if (isAuth()) {
      navigate(fromPage, { replace: true });
    }

    const loginData = {
      login: 'login',
      password: 'password',
    };
    withGoogle(loginData).then((resp) => {
      localStorage.setItem('login', resp.Login);
      localStorage.setItem('switchTo', resp.SwitchTo);
      localStorage.setItem('role', resp.Role);
      localStorage.setItem('hash', resp.Hash);
      localStorage.setItem('companies', resp.Companies);
      localStorage.setItem('company', resp.Company);
      localStorage.setItem('nolt', resp.Nolt);
      localStorage.setItem('thumbnail', resp.Thumbnail);
      localStorage.setItem('permissions', JSON.stringify(resp.Permissions));
      if (resp.Role === ROLE_GUEST) {
        fromPage = '/help';
      }
      navigate(fromPage, { replace: true });
    });
  }, []);

  useEffect(() => {
    if (clicks > 6) {
      setOpenPlanB(true);
    }
  }, [clicks]);

  const loginGoogle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.location.assign(apiHost + 'oauth/login');
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    const loginData = {
      login: formData.get('login') as string,
      password: formData.get('password') as string,
    };
    logIn(loginData, fromPage);
  };

  return (
    <StyledPage>
      <StyledContent
        data-testid="pnlLogin"
        onClick={() => setClicks((prevState) => prevState + 1)}
      >
        <LauncherImg src="/img/launcher.png" alt="launcher" />
        <LauncherImg
          className="mb-5"
          src="/img/saylogofull.svg"
          alt="saylogofull"
        />

        <div className="d-grid gap-2">
          <GoogleButtonMain
            as={Button}
            data-testid="btnLoginViaGoogle"
            size="lg"
            onClick={loginGoogle}
          >
            Login via Google
          </GoogleButtonMain>
        </div>
        <Collapse in={openPlanB}>
          <Form className="loginForm mt-5" noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-2">
              <Form.Control
                data-testid="txtLoginLogin"
                id="login"
                name="login"
                required
                type="text"
                placeholder="Username"
                size="lg"
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid login.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Control
                data-testid="txtLoginPassword"
                id="password"
                name="password"
                required
                type="password"
                placeholder="Password"
                size="lg"
              />
            </Form.Group>

            <div className="d-grid">
              <GoogleButtonSecondary
                as={Button}
                data-testid="txtLoginSubmit"
                type="submit"
                size="lg"
              >
                Login
              </GoogleButtonSecondary>
            </div>
          </Form>
        </Collapse>
      </StyledContent>
      <Toaster />
    </StyledPage>
  );
};
