import { Link, Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import {
  Nav,
  Navbar,
  NavDropdown,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import React from 'react';
import {
  PERMISSION_ANR,
  PERMISSION_CI_DI,
  PERMISSION_CREATIVES,
  PERMISSION_DEVICES,
  PERMISSION_DISCOVERY,
  PERMISSION_EDIT_USERS,
  PERMISSION_FEEDBACK,
  PERMISSION_GAMES_INFO,
  PERMISSION_GLOBAL_EXPERIMENTS,
  PERMISSION_HELP,
  PERMISSION_PROJECTS_RELEASE_HISTORY,
  PERMISSION_SAYKIT_BLACKLIST,
  PERMISSION_TOTALS,
  PERMISSION_UNITY_BLACKLIST,
  PERMISSION_UNITY_WHITELIST,
  ROLE_GUEST,
} from '../../services/_utils';
import styled from 'styled-components';
import { Toaster } from '../../components/Toast/Toast';

export const Header = () => {
  const {
    logOut,
    getLogin,
    hasPermission,
    isAdmin,
    getSwitchTo,
    getRole,
    getThumbnail,
  } = useAuth();

  const thumbNail = getThumbnail();
  const email = getLogin();

  const logoutView = () => {
    if (getSwitchTo() !== '') {
      return (
        <div>
          <span onClick={logOut}>Logout</span> <>switchTo - {getSwitchTo()}</>
        </div>
      );
    }

    return (
      <Logout>
        <span onClick={logOut}>Logout</span>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 0, hide: 3000 }}
          overlay={<Tooltip>{email}</Tooltip>}
        >
          <img
            className="iconUser"
            height={33}
            width={33}
            src={!!thumbNail ? thumbNail : '/img/user_thumbnail_default.png'}
          />
        </OverlayTrigger>
      </Logout>
    );
  };

  const hasAccessToSettingsDropdown = () => {
    return (
      hasPermission(PERMISSION_UNITY_BLACKLIST) ||
      hasPermission(PERMISSION_SAYKIT_BLACKLIST) ||
      hasPermission(PERMISSION_ANR)
    );
  };

  return (
    <StyledNavBar>
      <Navbar
        collapseOnSelect={true}
        expand="xl"
        className="navbarHeader"
        style={{ backgroundImage: `url("/img/launcher-sm.png")` }}
      >
        <Navbar.Brand
          as={Link}
          data-testid="lnkHeaderBrandProjects"
          to="/"
          className="brand"
        >
          <LogoImg
            className="pr-2"
            src="/img/saylogofull.svg"
            height="28"
            alt="saylogofull"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {getRole() !== ROLE_GUEST && (
              <Nav.Link
                as={Link}
                eventKey="1"
                data-testid="lnkHeaderProjects"
                className="noDecorLink"
                to="projects"
              >
                Games
              </Nav.Link>
            )}
            {hasPermission(PERMISSION_EDIT_USERS) && (
              <Nav.Link
                as={Link}
                eventKey="2"
                data-testid="lnkHeaderUsers"
                className="noDecorLink"
                to="users"
              >
                Users
              </Nav.Link>
            )}
            {hasPermission(PERMISSION_DEVICES) && (
              <Nav.Link
                as={Link}
                eventKey="3"
                data-testid="lnkHeaderDevices"
                className="noDecorLink"
                to="devices"
              >
                Devices
              </Nav.Link>
            )}
            {hasPermission(PERMISSION_GAMES_INFO) && (
              <Nav.Link
                as={Link}
                eventKey="4"
                data-testid="lnkHeaderGamesInfo"
                className="noDecorLink"
                to="games-info"
              >
                Games Info
              </Nav.Link>
            )}
            {hasPermission(PERMISSION_TOTALS) && getSwitchTo() === '' && (
              <Nav.Link
                as={Link}
                eventKey="5"
                data-testid="lnkHeaderTotals"
                className="noDecorLink"
                to="totals"
              >
                Totals
              </Nav.Link>
            )}
            {hasPermission(PERMISSION_CREATIVES) && (
              <Nav.Link
                as={Link}
                eventKey="6"
                data-testid="lnkHeaderCreatives"
                className="noDecorLink"
                to="creatives"
              >
                Creatives
              </Nav.Link>
            )}
            {hasPermission(PERMISSION_DISCOVERY) && (
              <Nav.Link
                as={Link}
                eventKey="7"
                data-testid="lnkHeaderDiscovery"
                className="noDecorLink"
                to="discovery"
              >
                Discovery
              </Nav.Link>
            )}
            {hasPermission(PERMISSION_HELP) && (
              <Nav.Link
                as={Link}
                eventKey="8"
                data-testid="lnkHeaderHelp"
                className="noDecorLink"
                to="help"
              >
                Help
              </Nav.Link>
            )}
            {hasPermission(PERMISSION_FEEDBACK) && (
              <Nav.Link
                eventKey="9"
                data-testid="lnkHeaderFeedback"
                className="noDecorLink"
                data-nolt
                href={`https://saygames.nolt.io/sso/${localStorage.getItem(
                  'nolt'
                )}?returnUrl=https://saygames.nolt.io`}
                target="_blank"
              >
                Feedback
              </Nav.Link>
            )}
            {hasPermission(PERMISSION_UNITY_WHITELIST) &&
              !hasPermission(PERMISSION_UNITY_BLACKLIST) && (
                <Nav.Link
                  as={Link}
                  eventKey="10"
                  data-testid="lnkHeaderUnity"
                  className="noDecorLink"
                  to="unity"
                >
                  Unity Whitelist
                </Nav.Link>
              )}
            {isAdmin() && (
              <Nav.Link
                as={Link}
                eventKey="11"
                data-testid="lnkHeaderHealth"
                className="noDecorLink"
                to="health"
              >
                Health
              </Nav.Link>
            )}
            {hasAccessToSettingsDropdown() && (
              <NavDropdown
                title="Settings"
                id="basic-nav-dropdown"
                className="settingsDdl"
              >
                {hasPermission(PERMISSION_ANR) && (
                  <NavDropdown.Item
                    as={Link}
                    eventKey="12"
                    data-testid="lnkHeaderAnr"
                    className="noDecorLink"
                    to="crash-anr"
                  >
                    Crash/ANR
                  </NavDropdown.Item>
                )}
                {hasPermission(PERMISSION_SAYKIT_BLACKLIST) && (
                  <NavDropdown.Item
                    as={Link}
                    eventKey="13"
                    data-testid="lnkHeaderSaykit"
                    className="noDecorLink"
                    to="saykit"
                  >
                    SayKit
                  </NavDropdown.Item>
                )}
                {hasPermission(PERMISSION_UNITY_BLACKLIST) && (
                  <NavDropdown.Item
                    as={Link}
                    eventKey="14"
                    data-testid="lnkHeaderUnity"
                    className="noDecorLink"
                    to="unity"
                  >
                    Unity
                  </NavDropdown.Item>
                )}
                {hasPermission(PERMISSION_PROJECTS_RELEASE_HISTORY) && (
                  <NavDropdown.Item
                    as={Link}
                    eventKey="15"
                    data-testid="lnkHeaderProjectReleaseHistory"
                    className="noDecorLink"
                    to="games-release-history"
                  >
                    Games Release History
                  </NavDropdown.Item>
                )}
                {hasPermission(PERMISSION_GLOBAL_EXPERIMENTS) && (
                  <NavDropdown.Item
                    as={Link}
                    eventKey="16"
                    data-testid="lnkExperiments"
                    className="noDecorLink"
                    to="experiments"
                  >
                    Experiments
                  </NavDropdown.Item>
                )}
              </NavDropdown>
            )}
            {/*{hasPermission(PERMISSION_TSAREVICH) && (*/}
            {/*  <div className="d-flex align-items-center ms-1">*/}
            {/*    <div>🤴</div>*/}
            {/*    <Nav.Link*/}
            {/*      as={Link}*/}
            {/*      eventKey="13"*/}
            {/*      reloadDocument*/}
            {/*      data-testid="lnkHeaderTsarevich"*/}
            {/*      className="noDecorLink ps-0"*/}
            {/*      to="tsarevich"*/}
            {/*    >*/}
            {/*      Tsarevich*/}
            {/*    </Nav.Link>*/}
            {/*  </div>*/}
            {/*)}*/}
          </Nav>
          <Nav>
            <Nav.Link data-testid="btnHeaderLogout" className="lgt">
              {logoutView()}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <main className="py-4">
        <Outlet />
        <Toaster />
      </main>
    </StyledNavBar>
  );
};

export default Header;

const StyledNavBar = styled.div`
  .navbar-brand {
    padding-top: 6px;
  }

  .navbarHeader {
    padding: 0 1rem;
    height: 56px;
    background: #00c565 no-repeat left center;
    background-size: 56px;
    font-size: 16px;

    .dropdown-item.active,
    .dropdown-item:active {
      background-color: rgba(0, 197, 101, 0.1);
    }

    .noDecorLink {
      @media (max-width: 1200px) {
        padding: 0.75rem 0;
      }

      &:hover,
      &:focus {
        color: rgba(0, 0, 0, 0.55) !important;
      }

      color: rgba(0, 0, 0, 0.55) !important;
    }
  }

  .navbar-collapse.collapsing {
    background-color: #00c565;
    z-index: 110;
    padding-left: 1rem;
  }

  .navbar-collapse.collapse {
    background-color: #00c565;
    z-index: 100;
    @media (max-width: 1200px) {
      padding: 1rem 0.75rem;
      margin-top: 0.5rem;
    }
  }

  .settingsDdl {
    @media (max-width: 1200px) {
      padding-top: 0.75rem;
    }

    .dropdown-menu {
      z-index: 200;
      @media (max-width: 1200px) {
        border: none;
        background-color: #00c565;
        padding-left: 1rem;
      }
    }
  }

  .lgt {
    @media (max-width: 1200px) {
      margin-top: 2rem;
      margin-bottom: 0.5rem;
      padding: 0.75rem 0;
    }
  }

  .nav-link {
    padding: 0;
  }
`;

const LogoImg = styled.img`
  display: inline-block;
  vertical-align: top;
  padding: 0 8px 0 34px;
`;

const Logout = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;

  .iconUser {
    background-color: transparent;
    margin-left: 15px;
    border-radius: 20px;
    cursor: default;
  }
`;
