import React from 'react';
import styled from 'styled-components';

const StyledHammer = styled.div`
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
`;

export function MCHammer(props: { style?: React.CSSProperties }) {
  return (
    <StyledHammer style={props.style}>
      <img width="154" height="171" alt="Hammer" src="/img/hammer.gif" />
    </StyledHammer>
  );
}
