import { apiHost } from '../../environments/environment';
import { useLocation, useNavigate } from 'react-router-dom';

const useAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const withGoogle = async (loginData: { login: string; password: string }) => {
    try {
      const response = await fetch(apiHost + 'login', {
        method: 'POST',
        body: JSON.stringify(loginData),
        credentials: 'include',
      });

      if (response.status === 404) {
        throw new Error('404');
      } else if (!response.ok && response.status !== 404) {
        const errMsg = await response.text();
        throw new Error(errMsg);
      }

      return await response.json();
    } catch (e) {
      if (e instanceof Error) {
        console.log(JSON.parse(e.message));
      }
      throw e;
    }
  };
  const logIn = (
    loginData: { login: string; password: string },
    fromPage: string
  ) => {
    fetch(apiHost + 'login', {
      method: 'POST',
      body: JSON.stringify(loginData),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        localStorage.setItem('login', resp.Login);
        localStorage.setItem('switchTo', resp.SwitchTo);
        localStorage.setItem('role', resp.Role);
        localStorage.setItem('hash', resp.Hash);
        localStorage.setItem('companies', resp.Companies);
        localStorage.setItem('company', resp.Company);
        localStorage.setItem('permissions', JSON.stringify(resp.Permissions));
        navigate(fromPage, { replace: true });
      });
  };

  const logOut = () => {
    fetch(apiHost + 'logout', {
      method: 'POST',
      body: JSON.stringify({ logout: true }),
      credentials: 'include',
    }).then(() => {
      localStorage.clear();
      sessionStorage.setItem('from', '');
      navigate('/login', { replace: true });
    });
  };

  const getLogin = () => {
    return localStorage.getItem('login');
  };

  const getHash = () => {
    return localStorage.getItem('hash');
  };

  const getRole = () => {
    return localStorage.getItem('role');
  };

  const getCompanies = () => {
    let c = localStorage.getItem('companies');
    return !!c ? JSON.parse(c) : ([] as string[]);
  };

  const getSwitchTo = () => {
    return localStorage.getItem('switchTo');
  };

  const hasPermission = (permission: string) => {
    return localStorage.getItem('permissions')?.includes(permission) || false;
  };

  const isAdmin = () => {
    return localStorage.getItem('role') === 'Admin';
  };

  const isQaManager = () => {
    return localStorage.getItem('role') === 'QAManager';
  };

  const isAuth = () => {
    return !!localStorage.getItem('hash');
  };

  const getThumbnail = () => {
    return localStorage.getItem('thumbnail');
  };

  return {
    withGoogle,
    logIn,
    logOut,
    isAuth,
    getLogin,
    getHash,
    getRole,
    getCompanies,
    getSwitchTo,
    hasPermission,
    isAdmin,
    isQaManager,
    getThumbnail,
  };
};

export default useAuth;
